import React from 'react';

const Home = () => {
  return (
    <div style={{ height: 'calc(100vh - 6rem)' }} className="flex justify-center items-center">
      <h1 className="text-4xl font-semibold">Bienvenido</h1>
    </div>
  );
}

export default Home;
